@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");

:root {
  --gutter: 20px;
}

* {
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 18px;
  font-family: "Fredoka One", cursive;
  text-transform: uppercase;
  background: linear-gradient(to bottom, #21233d 0%, #633d59 100%);
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
  color: #fff;
}

header,
section,
footer {
  margin: 0 auto;
}

header {
  display: grid;
  grid-template-columns: 1fr;
  margin: 40px;
  align-items: center;
  justify-items: center;
}

section.lobby {
  margin: 40px;
}

h1,
h2,
h3 {
  font-family: "Fredoka One", sans-serif;
  font-weight: 700;
  margin: 0 0 40px;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.menu {
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 20px;
  margin-bottom: 10px;
  align-items: center;
  p {
    margin: 0;
    border-radius: 10px;
    line-height: 32px;
  }
  svg {
    z-index: 1000;
    height: 24px;
    width: 24px;
    margin-left: 10px;
    path {
      fill: #fff;
    }
  }
}

.game-menu {
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #21233d 0%, #633d59 100%);
  z-index: 9999;
  justify-items: center;
  align-items: center;
  padding: 40px;
  -webkit-animation: scale-in-center 0.3s
    cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: scale-in-center 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  .modal-container {
    width: 100%;
  }
  &.skills {
    grid-gap: 10px;
  }
}

@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.players {
  margin: 5px 10px 10px;
  padding-bottom: 75px;
  display: grid;
  grid-template-columns: minmax(49%, 1fr) minmax(49%, 1fr);
  grid-gap: 2%;
  .player-name svg {
    height: 16px;
    margin-left: 5px;
    color: yellow;
  }
  .player {
    position: relative;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0.6);
    color: #fff;
    margin-bottom: 10px;
    &.active {
      background: rgba(0, 0, 0, 0.6);
    }
    &.out {
      background: #8e423f;
      .player-cards .card:first-child div:last-child {
        height: 40px;
        width: 40px;
        background-image: url("https://image.flaticon.com/icons/svg/879/879130.svg");
        img {
          display: none;
        }
      }
      .player-name svg {
        color: #fff;
      }
    }

    .player-top {
      display: grid;
      grid-template-rows: auto auto;
      grid-gap: 5px;
      margin: 0;
      align-items: center;
    }

    .player-health {
      display: grid;
      align-items: center;
      grid-gap: 10px;
      grid-template-columns: 1fr auto;
      .health-bar {
        height: 10px;
        border: 1px solid rgba(255, 255, 255, 0.8);
        border-radius: 10px;
        max-width: 100%;
      }
      .current-health {
        border-radius: 10px;
        height: 8px;
        transition: all 1s;
        max-width: 100%;
      }
    }

    .player-cards {
      display: flex;
      overflow-x: scroll;
      padding-top: 5px;
      margin-top: 5px;
      .card {
        position: relative;
        flex: 0 0 60px; /* doesn't grow nor shrink, initial width set to 25% of the parent's */
        height: 80px;
        display: grid;
        margin-right: 1%;
        margin-left: -5%;
        justify-content: center;
        align-content: center;
        background: #3056b5;
        border: 2px solid #fff;
        border-radius: 10px;
        -webkit-animation: slit-in-vertical 0.2s ease-out;
        animation: slit-in-vertical 0.2s ease-out;
        img {
          max-width: 40px;
        }
        .value {
          position: absolute;
          top: 5px;
          right: 5px;
          font-size: 14px;
        }
        &:first-child {
          margin-left: 0;
          background-color: darken(#3056b5, 20%);
        }
        &.first {
          border: 2px dotted rgba(255, 255, 255, 0.5);
          background: none;
        }
        transition: all 0.1s;
        &:hover {
          cursor: pointer;
          transform: translateY(-5px);
        }
      }
    }

    .player-actions {
      margin-top: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      &.waiting {
        grid-template-columns: 1fr;
      }
    }

    .player-loading {
      z-index: 9998;
      transition: all 0.2s;
      background: rgba(0, 0, 0, 0.5);
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 10px;
      .loading-container {
        width: 100%;
        height: 100%;
      }
    }
  }

  button {
    margin-bottom: 5px;
  }
}

.actions {
  margin: 10px 10px 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  &.waiting {
    grid-template-columns: 1fr;
  }
  button {
    margin-bottom: 5px;
  }
}

.skills {
  margin: 10px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  button {
    margin-bottom: 0;
    padding: 20px;
    line-height: 18px;
    min-height: 30px;
  }
}

.message {
  text-align: center;
  line-height: 1.5;
  margin-bottom: 10px;
  background: rgba(255, 0, 0, 0.6);
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  transition: all 0.2s;
  p {
    margin: 0;
  }
}

section.game .message {
  margin: 10px;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-7-6 21:6:54
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slit-in-vertical
 * ----------------------------------------
 */
@-webkit-keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
}
@keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
}

.button {
  position: relative;
  text-align: center;
  display: block;
  border: none;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  color: #fff;
  width: 100%;
  font-size: 24px;
  padding: 20px;
  margin-bottom: 10px;
  text-decoration: none;
  outline: none;
  transition: all 0.25s;
  text-transform: uppercase;
  min-height: 70px;
  &.button-small {
    font-size: 20px;
  }
  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.4);
  }
  &:disabled:hover {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.6);
  }
  &:disabled {
    opacity: 0.4;
  }
  .loading-container {
    position: relative;
  }
}

.input {
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  color: #333;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  label {
    display: block;
    color: #666;
    font-size: 12px;
    margin-bottom: 5px;
  }
  input {
    background: none;
    text-transform: uppercase;
    text-align: center;
    display: block;
    position: relative;
    border: none;
    color: #333;
    width: 100%;
    font-size: 25px;
    border-radius: 10px;
    text-decoration: none;
    outline: none;
  }
}

.toggle {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  align-items: center;
  justify-items: center;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  color: #333;
  border-radius: 10px;
  margin-bottom: 10px;
  font-size: 25px;
  height: 70px;
  .toggle-left span,
  .toggle-right span {
    display: block;
    background: #ddd;
    color: #888;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    line-height: 40px;
    transition: all 0.25s;
    margin: 0 15px;
    &:hover {
      cursor: pointer;
      background: #ccc;
    }
    &:active {
      cursor: pointer;
      background: #bbb;
      color: #333;
    }
  }
  .toggle-middle {
    display: grid;
    grid-template-rows: auto 1fr;
    .toggle-label {
      display: block;
      color: #666;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
}

.loading-container {
  position: absolute;
  display: grid;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
}

.loading {
  display: inline-block;
  position: relative;
  width: 58px;
  height: 10px;
  div {
    position: absolute;
    top: 0px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fdfeff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) {
      left: 0px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 0px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 24px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 48px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.divider {
  position: relative;
  color: #fff;
  margin-bottom: 15px;
  display: grid;
  text-align: center;
  span {
    position: relative;
    background-color: #12151d;
    width: 40px;
    margin: 0 auto;
  }
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 1px;
    border-top: 1px solid #999;
  }
}

.how-to-play {
  text-align: center;
  line-height: 1.25;
  p:first-child {
    font-size: 30px;
  }
  span {
    display: block;
    margin: 0;
  }
  svg {
    height: 1em !important;
  }
}

.host-actions {
  margin: 10px;
}

.controls {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #21233d;
  z-index: 100;
}
